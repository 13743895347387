<template>
  <div class="wraper pagesxpurchase">

    <div id="printDiv" v-if="showPrinter">ahahahahahha</div>

    <SXPurchaseTable ref="SXPurchaseTable"></SXPurchaseTable>
    <!--新增申购单-->
    <el-dialog title="" :visible.sync="dialogVisible_add" custom-class="cus_dialog cus_dialogFullHeight" width="90%">
      <div class="flexBetween w_100 h_100">
        <div class="flexCloumn" style="height: 100%;width: 40%;">
          <div class="flexCloumnSingleCenter boxShadow" style="height: 60%;width: calc( 100% - 0px );">
            <div class="titleBar flexStart w_100">
              <div class="div flexStart">{{ dialogTitle }}</div>
            </div>
            <div class="flex_1" style="overflow-y: auto;width:100%;margin-bottom: 10px;">
              <el-form class="" :model="EditItem" ref="addzc" label-position="left" label-width="110px"
                style="width:calc( 100% - 10px );">

                <el-form-item label="申请人:">
                  <el-input :value="userinfo.username + '-' + EditItem.dept_name" size="mini" disabled></el-input>
                </el-form-item>
                <el-form-item label="所属部门:">
                  <el-cascader size="small" style="width:80%" ref="treeCascader" :options="deptTree"
                    :props="deptCascaderProps" v-model="EditItem.dept_id" :show-all-levels="false" @change="changeDept"
                    placeholder="请选择" clearable>
                  </el-cascader>
                </el-form-item>


                <el-form-item label="购买预算(元):" prop="title">
                  <el-input v-model="EditItem.howmuch" size="mini" disabled placeholder="填写采购单价和数量后自动核算">
                  </el-input>
                </el-form-item>

                <el-form-item label="购买原因:" prop="remark">
                  <el-input type="textarea" :autosize="{ minRows: 3 }" v-model="EditItem.remark" size="mini"
                    :disabled="disabled" placeholder="请输入申购理由,用途等"></el-input>
                </el-form-item>





                <el-form-item label="图片:">
                  <div class="flexStart">
                    <div class="updimage" v-for="(f, idx) in files" :style="{ backgroundImage: 'url(' + f.src + ')' }"
                      style="position: relative">
                      <img :src="f.src" @click="handlePictureCardPreview(f.src)"
                        style="opacity: 0;width: 100%;height: 100%;" />
                      <div class="flexCenter" @click="delImage(idx)"
                        style="position: absolute;top: -8px;right: -8px;width:20px;height:20px;
                            color: #fff;background-color:#409eff;border-radius: 50%;border: 1px solid #fff;cursor: pointer;">
                        <i class="el-icon-close"></i>
                      </div>
                    </div>
                    <el-upload v-if="files.length < 3" action="" :http-request="uploadOss" :on-success="uploadCover"
                      :on-remove="handleRemove" :show-file-list="false" name="image">
                      <div class="flexCenter uploadBox" style="width: 100px;height: 100px;border:1px dashed #909399;">
                        <i class="el-icon-plus" style="font-size: 26px;"></i>
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>

              </el-form>
            </div>
          </div>


          <div class="flexCloumn boxShadow mt10" style="height: calc( 40% - 10px);width: 100%;">
            <div class="titleBar flexStart w_100">
              <div class="div flexStart">流程状态</div>
            </div>
            <processchoose v-if="processShow" ref="processchoose" :dept_id="EditItem.dept_id" :code="process_code"
              v-on:ischooseuser="isChooseuser" v-on:chooseUser="chooseUser" class="flex_1" style="overflow-y: auto;">
            </processchoose>
          </div>
        </div>
        <div class="flexCloumnSingleCenter boxShadow" style="height: 100%;width: calc( 60% - 10px);">
          <el-row class="flexStart w_100">
            <!--            <el-popover placement="top-start" width="900" trigger="click">-->
            <!--              <SXListTable ref="SXListTable"></SXListTable>-->
            <!--              <el-button slot="reference" size="mini" type="primary" style="margin-left: 10px;">选择耗材</el-button>-->
            <!--            </el-popover>-->
            <el-button size="mini" type="primary" @click="dialogVisible_zcList = true">选择耗材
            </el-button>

            <el-button size="mini" type="danger" @click="delSlct" style="margin-left: 10px;">移除耗材</el-button>
            <el-button size="mini" type="primary" @click="dialogVisible_addsxhc = true">新增耗材</el-button>
            <!-- <el-button size="mini" type="primary" @click="dialogVisible_sxType=true">新增耗材分类</el-button> -->

            <!--              <el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadAttch"
                     :show-file-list="false"
                     name="image">

                     <el-button size="mini" type="primary" style="margin-left: 10px;" >导入耗材</el-button>
          </el-upload>-->



          </el-row>
          <el-row class="flex_1 w_100 mt10">
            <el-table ref="mySGtable" :data="arr_json" height="100%" stripe border
              @selection-change="handleSelectionChange" style="width:fit-content;width:100%;">

              <el-table-column type="selection" width="55" align="center" />
              <el-table-column label="操作" width="60" fixed="left" align="center">
                <template slot-scope="scope">
                  <el-tag size="mini" style="cursor: pointer;" type="danger" @click="removeHC(scope.$index)">移除</el-tag>
                </template>
              </el-table-column>
              <el-table-column type="index" label="序号" width="55" align="center" />
              <!--                <el-table-column prop="title" label="耗材名称/品牌/型号/分类" width="200" :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                    <div class="flexStart">
                      <div style="color: #409EFF;">{{ scope.row.title }}</div>&nbsp;
                      <div>{{ scope.row.pinpai }}</div>&nbsp;
                      <div>{{ scope.row.xinghao }}</div>&nbsp;

                    </div>
                    <div class="flexStart" style="color: #909399;">分类：{{ scope.row.typename1 }}</div>
                  </template>
                </el-table-column>-->
              <el-table-column prop="title" label="耗材名称" show-overflow-tooltip width="200" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">耗材名称</span>
                </template>
              </el-table-column>
              <el-table-column prop="pinpai" label="品牌" width="100" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">品牌</span>
                </template>
              </el-table-column>
              <el-table-column prop="xinghao" label="型号" width="100" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">型号</span>
                </template>
              </el-table-column>
              <!--                <el-table-column prop="typename1" label="分类"  width="100" align="center"/>-->
              <el-table-column prop="storename" label="存放地" width="100" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">存放地</span>
                </template>
              </el-table-column>

              <el-table-column prop="cgJine" width="150" :show-overflow-tooltip="true" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">采购单价(元)</span>
                </template>
                <template slot-scope="scope">
                  <el-input type="number" v-model="scope.row.cgJine" @input="handleCGData" class="cgInput"
                    @mousewheel.native.prevent :controls="false" size="mini" :min="0"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="jine" width="170" :show-overflow-tooltip="true" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">*采购数量</span>
                </template>
                <template slot-scope="scope">
                  <el-input type="number" v-model="scope.row.cgNum" @input="handleCGData" class="cgInput"
                    :controls="false" size="mini" :min="0" style="width:100%;"></el-input>
                  <!-- <span style="width: 14px;height: auto;margin-left: 4px;display: inline-block;">{{ scope.row.jldw
                    }}</span> -->
                </template>
              </el-table-column>
              <el-table-column prop="cgTotal" label="采购总价(元)" :show-overflow-tooltip="true" width="120" align="center"
                :formatter="formatter" />

              <el-table-column prop="img" label="图片" width="120" align="center">


                <template slot-scope="scope">

                  <div class="flexStart">

                    <div v-if="arr_json[scope.$index].img" class="updimage"
                      style="position: relative;margin-top:10px;width: 100px;height: 100px;">
                      <img :src="arr_json[scope.$index].img"
                        @click="handlePictureCardPreview(arr_json[scope.$index].img)" style="width: 80px;height: 80px;">
                      <div class="flexCenter" @click="delImage1(scope.$index)"
                        style="position: absolute;top: -8px;right: -8px;width:20px;height:20px;
                            color: #fff;background-color:#409eff;border-radius: 50%;border: 1px solid #fff;cursor: pointer;">
                        <i class="el-icon-close"></i>
                      </div>
                    </div>
                    <el-upload v-else action="" :http-request="uploadOss"
                      :on-success="(response, file, fileList) => uploadCover1(response, file, fileList, scope.$index)"
                      :on-remove="handleRemove" :show-file-list="false" name="image">

                      <div class="flexCenter uploadBox" style="width: 80px;height: 80px;border:1px dashed #909399;">
                        <i class="el-icon-plus avatar-uploader-icon" style="font-size: 26px;"></i>
                      </div>
                    </el-upload>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="remark" label="链接" :show-overflow-tooltip="true" width="200" align="center">
                <template slot-scope="scope">
                  <div class="" style="max-height: 200px;overflow-y: auto">
                    <el-input type="textarea" :rows="4" v-model="scope.row.externalLinks" size="mini"
                      @input="handleExternalLinks" style="width: 100%;"
                      placeholder="请填充或粘贴外部链接，多条外链请用英文分号;隔开"></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="user_remark" label="备注" :show-overflow-tooltip="true" width="200" align="center">
                <template slot-scope="scope">
                  <el-input type="textarea" :rows="4" v-model="scope.row.user_remark" size="mini" @input="handleRemark"
                    style="width: 100%;" placeholder="备注"></el-input>
                </template>
              </el-table-column>

            </el-table>
          </el-row>

        </div>
      </div>

      <div slot="footer">
        <el-button v-if="!disabled" type="primary" size="small" @click="saveForm()">提交</el-button>

        <el-button type="primary" @click="dialogVisible_add = false" plain size="small">取消</el-button>
      </div>

    </el-dialog>
    <!--查看-->
    <SGDetail v-if="dialogVisible_detail" :dialogVisible_detail="dialogVisible_detail" :dialogTitle="dialogTitle"
      :EditItem1="EditItem"></SGDetail>

    <!--审核-->
    <SGVerify v-if="dialogVisible_verify" :dialogVisible_verify="dialogVisible_verify" :dialogTitle="dialogTitle"
      :EditItem="EditItem"></SGVerify>

    <!--    添加耗材-->
    <SXhcAdd v-if="dialogVisible_addsxhc" :dialogVisible_addsxhc="dialogVisible_addsxhc"></SXhcAdd>

    <!--添加分类-->
    <el-dialog title="实训耗材分类" :visible.sync="dialogVisible_sxType" width="600px" custom-class="width_800 cus_dialog">

      <sxType name="实训耗材分类" type="sx" parentPage="sxlist"></sxType>

    </el-dialog>
    <!--        选择耗材-->
    <SXListTable v-if="dialogVisible_zcList" :dialogVisible_zcList="dialogVisible_zcList" dialogTitle="选择耗材"
      ref="SXListTable"></SXListTable>



    <Deptuser v-if="showUserDialog" @getUser="getUser" @closeUser="closeUser"></Deptuser>

    <biaoqian v-if="showBiaoqian" :zclist="bqZcList"></biaoqian>
    <div style="display: none;">
      <el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadAttch" :show-file-list="false"
        name="image">

        <el-button size="mini" id="addXlsbtn">+</el-button>
      </el-upload>
    </div>


    <el-dialog title="错误日志" :visible.sync="dialogErrorVisible" :close-on-click-modal="false">
      <div style="padding-top: 10px;" v-for="item in ErrLog">{{ item }}</div>
    </el-dialog>

    <!--预览图片-->
    <el-dialog width="600px" :visible.sync="dialogVisibleImageUrl" @close="dialogVisibleImageUrl = false"
      :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>




    <!--打印申购单-->
    <!--    <Print  :dialogTitle="dialogTitle" :EditItem="EditItem"></Print>-->




    <!--打印单-->
    <el-dialog :title="dialogTitle" :visible="dialogVisible_print" :close-on-click-modal="false" width="900px"
      custom-class="cus_dialog cus_dialog_print" @close="dialogVisible_print = false">
      <div style="text-align: right; position: absolute; top:2px; right: 50px;">
        <el-button v-print="'#printMe'" icon="el-icon-printer" type="warning">打印</el-button>
      </div>
      <SGPrintForm v-if="dialogVisible_print" :printId="printId"></SGPrintForm>

    </el-dialog>
  </div>
</template>

<script>
import Pagination from '../../com/pagination.vue'
import Deptuser from '../../com/deptuser.vue'
import biaoqian from '../zc/biaoqian.vue'
import processchoose from "../../com/processchsmini.vue"
import SXhcAdd from "../components/SXhcAdd";

export default {
  components: {
    SXhcAdd,
    Pagination,
    Deptuser,
    biaoqian,
    processchoose,
    SXPurchaseTable: () => {
      return import('./com_sxpurchase/SXPurchaseTable.vue')
    },
    SXListTable: () => {
      return import('../components/SXListTable.vue')
    },
    SGDetail: () => {
      return import('./com_sxpurchase/SGDetail.vue')
    },
    SGVerify: () => {
      return import('./com_sxpurchase/SGVerify.vue')
    },
    sxType: () => {
      return import('../components/Type.vue')
    },
    Print: () => {
      return import('./com_sxpurchase/Print.vue')
    },
    SGPrintForm: () => {
      return import('../../../h5/print/sg.vue')
    },
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value && value != 0) {
        return callback(new Error('请填写入库数量!'));
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'));
      } else {
        if (value <= 0) {
          callback(new Error('不能小于0'));
        } else {
          callback();
        }
      }
    };

    return {
      //--审批流程用----
      process_code: "hcsg",
      processShow: false,
      process_id: 0,
      needChoose: false,//是否需要选择下级
      choosedUser: [],//已选择的用户
      //--审批流程用----
      curDate: '',
      userinfo: {},
      searchForm: {},
      dialogTitle: '新增',
      dialogVisible_add: false,
      dialogVisible_sxType: false,
      EditItem: {
        arr_json: ''
      },
      arr_json: [],
      dialogVisibleImageUrl: false,
      dialogImageUrl: '',//预览图片
      dialogVisible_addsxhc: false,
      dialogVisible_detail: false,
      dialogVisible_verify: false,
      DataList: [],
      multipleSelection: [],
      queryForm: {},
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },

      disabled: false,
      typeList: [],
      areaList: [],
      files: [],
      logList: [],
      temp_list: [],//todo 未用备用
      showUserDialog: false,
      userDialogType: null,
      drawer: false,//高级搜索面板
      printObj: {
        id: "#printDiv",
        popTitle: "",
        extraCss: "",
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
      },
      showPrinter: false,
      showBiaoqian: false,
      bqZcList: [],
      dialogErrorVisible: false,
      ErrLog: [],
      page_hc: {
        current_page: 1,
        pre_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogVisible_zcList: false,
      storeNum: null,
      rules_store: {
        num: [
          { validator: checkNum, trigger: 'blur' }
        ],
      },
      purchaseType: [],
      dialogVisible_print: false,
      printId: '',
      deptTree: [],
      deptCascaderProps: {
        label: "dept_name",
        value: "id",
        children: "children",
        emitPath: false,
        checkStrictly: true,
      },
      documenttitle_print: '',
      documenttitle_init: ''
    }
  },
  watch: {
    dialogVisible_print(n, o) {
      if (n) {
        console.log(4251111111, this.$store.state.pmid, this.documenttitle_init, n, o)
        document.title = this.documenttitle_print || '申购单'
      } else if (this.documenttitle_init) {
        document.title = this.documenttitle_init || '申购单'
      }
    }
  },
  mounted() {
    this.getConfig()
    this.getType();
    this.getPurchaseType()
    this.getArea();
    this.getCurDate();
    this.getCreateUser()
    //--审批流程用----
    this.getProcessID()
    this.getOrgList()
  },
  methods: {
    getConfig() {
      this.$http.post("/api/sys_dictionary_list", {
        pmid: this.$store.state.pmid
      }).then(res => {
        if (res.data && res.data.length > 0) {
          res.data.map(a => {
            if (a.remark.indexOf('app基础配置') > -1 && a.key_values) {
              let json_Arr = JSON.parse(a.key_values)
              for (let item of json_Arr) {
                if (item.field == "schoolname") {
                  this.documenttitle_init = item.value
                }
              }
            }
          })
        }
      })
    },
    formatter(row, col, cellValue, index) {
      return Number(cellValue).toFixed(2)
    },
    //--审批流程用----
    isChooseuser(e) {//设置是否需要选人
      this.needChoose = e
    },
    chooseUser(e) {//设置选择的人
      this.choosedUser = e
    },
    getProcessID() {
      this.$http.post("/api/form_process", {
        process_code: this.process_code
      }).then(res => {
        this.process_id = res.data.id

      })
    },
    //--审批流程用----
    getList() {
      let _this = this
      let params = {}, arr = []
      params.title = _this.searchForm.title
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.type = 'sx'
      this.$http.post("/api/sx_sg_list", params).then(res => {
        this.DataList = res.data.data;
        if (res.data && res.data.page) {
          this.page = res.data.page
          this.page_hc = res.data.page
        }

      })
    },
    getCreateUser() {

      this.$http.post("/api/get_teacher_info").then(res => {
        this.userinfo = res.data
        this.EditItem.dept_name = this.userinfo.dept_name
        this.EditItem.dept_id = this.userinfo.dept.length ? this.userinfo.dept[0].id : null;
      })
    },
    getPurchaseType() {
      this.$http.post("/api/sys_dictionary_list", { key_type: "purchaseType" }).then(res => {
        if (res.data && res.data.length > 0) {
          this.purchaseType = JSON.parse(res.data[0].key_values)
        }
      })
    },
    // 新增
    add() {
      this.getCreateUser()
      let _this = this
      this.dialogTitle = '新增申购单'
      this.dialogVisible_add = true
      this.disabled = false
      this.arr_json = []
      this.files = []
      this.EditItem = {}
      _this.$nextTick(() => {
        // console.log(_this.arr_json)
        _this.$forceUpdate()
        setTimeout(() => {
          this.processShow = true
        }, 500);
      })
    },

    uploadCover(e) {
      this.files.push({ fileName: e.fileName, src: e.src, name: e.fileName, url: e.src })
    },
    delImage(idx) {
      this.files.splice(idx, 1)
    },
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisibleImageUrl = true;
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      this.files = fileList
    },
    uploadPreview(file) {
      // console.log(879,file)
      window.open(file.src || file.url)
    },
    refreshType() {
      console.log(463, 'refreshType')
      this.getType()
      this.dialogVisible_sxType = false
    },
    callBack_addsxhc(row) {
      this.$http.post("/api/sx_hc_xx_list", { id: row.id }).then(res => {
        console.log(3747673738497879, res.data.data[0])
        let row1 = res.data.data ? res.data.data[0] : row
        this.chooseHC(row1)
      })
    },
    chooseHC(row) {
      let _this = this
      let obj = {}, arr = []
      obj = JSON.parse(JSON.stringify(row))
      arr = JSON.parse(JSON.stringify(_this.arr_json))
      obj.img = ''
      obj.cgJine = obj.cgJine ? obj.cgJine : obj.jine
      obj.cgNum = obj.cgNum ? obj.cgNum : 1
      if (arr && arr.length > 0) {
        if (_this.dep_Arr1(arr, obj.id)) {//dep_Arr1 id存在为false 不存在返回true
          arr.push(obj)
          _this.arr_json = arr
        } else {
          _this.$message.warning(`${obj.title}已存在,不要重复选择哦~`)
        }
      } else {
        arr.push(obj)
        _this.arr_json = arr
      }

      let total = 0
      if (_this.arr_json && _this.arr_json.length > 0) {
        _this.arr_json.map(a => {
          a.cgTotal = 0
          if ((!a.cgJine && a.cgJine != 0) || (!a.cgNum && a.cgNum != 0)) {
            a.cgTotal = 0
          } else {
            a.cgTotal = this.$mul(a.cgJine, a.cgNum)
          }
          total += a.cgTotal
        })
        this.EditItem.howmuch = total
      } else {
        this.EditItem.howmuch = 0
      }
      _this.$nextTick(() => {
        // console.log(_this.arr_json)
        _this.$forceUpdate()
      })

    },
    dep_Arr1(arr, id) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.id == id) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },
    removeHC(idx) {
      let _this = this
      _this.arr_json.splice(idx, 1)
      let total = 0
      if (_this.arr_json && _this.arr_json.length > 0) {
        _this.arr_json.map(a => {
          a.cgTotal = 0
          if ((!a.cgJine && a.cgJine != 0) || (!a.cgNum && a.cgNum != 0)) {
            a.cgTotal = 0
          } else {
            a.cgTotal = this.$mul(a.cgJine, a.cgNum)
          }
          total += a.cgTotal
        })
        this.EditItem.howmuch = total
      } else {
        this.EditItem.howmuch = 0
      }
      _this.$nextTick(() => {
        // console.log(_this.arr_json)
        _this.$forceUpdate()
      })
    },
    handleCGData(index) {
      console.log('handleCGData')
      let _this = this
      let total = 0
      if (this.arr_json) {
        this.arr_json.map(a => {
          a.cgTotal = 0
          if ((!a.cgJine && a.cgJine != 0) || (!a.cgNum && a.cgNum != 0)) {
            a.cgTotal = 0
          } else {
            a.cgTotal = this.$mul(a.cgJine, a.cgNum)
          }
          total += a.cgTotal
        })
        this.EditItem.howmuch = total
        this.EditItem = { ..._this.EditItem }

      } else {
        this.EditItem.howmuch = 0
        this.EditItem = { ..._this.EditItem }
      }
    },
    handleRemark() {
      // console.log(this.arr_json)
    },
    handleExternalLinks() {
      // console.log(this.arr_json)
    },
    uploadCover1(e, b, c, index) {
      // console.log(1082,e,b,c,index)
      let _this = this
      this.arr_json[index].img = e.src
      this.arr_json = [..._this.arr_json]
      _this.$nextTick(() => {
        _this.$forceUpdate()
      })
      // this.files.push({fileName: e.fileName, src: e.src, name: e.fileName, url: e.src})
    },
    delImage1(index) {
      let _this = this
      this.arr_json[index].img = ''
      this.arr_json = [..._this.arr_json]
      _this.$nextTick(() => {
        _this.$forceUpdate()
      })
    },

    editItem(row, flag) {
      let _this = this
      this.dialogTitle = '编辑'
      this.dialogVisible_add = true
      this.disabled = false

      let obj = JSON.parse(JSON.stringify(row))


      this.EditItem = { ..._this.EditItem }


      obj.arr_json = obj.arr_json ? JSON.parse(obj.arr_json) : []
      obj.process_json = obj.process_json ? JSON.parse(obj.process_json) : []
      obj.files = obj.files ? JSON.parse(obj.files) : []
      obj.hc_list = obj.hc_list ? JSON.parse(obj.hc_list) : []

      this.EditItem = obj
      this.arr_json = obj.hc_list

      this.files = obj.files
      _this.$nextTick(() => {
        // console.log(_this.arr_json)
        _this.$forceUpdate()
      })
    },
    view(row, type) {
      let _this = this
      // console.log(row,type)
      let obj = JSON.parse(JSON.stringify(row))
      obj.arr_json = obj.arr_json ? JSON.parse(obj.arr_json) : []
      obj.process_json = obj.process_json ? JSON.parse(obj.process_json) : []
      obj.files = obj.files ? JSON.parse(obj.files) : []
      // obj.hc_list = obj.hc_list ? JSON.parse(obj.hc_list) : []
      if (obj.hc_list) {
        let arr = JSON.parse(obj.hc_list)
        arr.map(a => {
          let links = []
          a.links = []
          if (a.externalLinks) {
            if (a.externalLinks.indexOf(';') > 0) {
              links = a.externalLinks.split(';')
              a.links = links
            } else if (a.externalLinks.indexOf('；') > 0) {
              links = a.externalLinks.split('；')
              a.links = links
            } else {
              a.links = [a.externalLinks]
            }
          }

        })
        obj.hc_list = arr
      }
      this.EditItem = obj
      if (type == 'verify') {
        this.dialogTitle = '审核'
        this.dialogVisible_verify = true
      } else {
        this.dialogTitle = '申购单详情'
        this.dialogVisible_detail = true
      }
      // console.log(this.EditItem.process_json)

    },
    print(row) {
      this.printId = row.id
      this.dialogTitle = '打印申购单'
      this.getPrintInfo(row.id).then(_ => {
        this.dialogVisible_print = true
      })
    },
    getPrintInfo(id) {
      return new Promise((resolve, reject) => {
        this.$http.post('/api/zichan_sg_one', {
          id: id,
          pmid: this.$store.state.pmid
        }).then(res => {
          if (res && res.data) {
            let resData = res.data
            if (resData.cdate) {
              resData.cdate = resData.cdate.substr(0, 10)
            }
            let desc = `${resData.username}_${resData.cdate}的申购单`
            this.documenttitle_print = desc
            resolve()
          }
          resolve()
        }).catch(err => {
          resolve()
        })
      })
    },
    preCheckData(e) {

      let DataInfo = this.EditItem;
      console.log('this.needChoose');
      console.log(this.choosedUser);

      if (this.needChoose && (!this.choosedUser || this.choosedUser.length == 0)) {
        // this.$message({
        //   type: 'error',
        //   message: '请选择审批人'
        // });
        this.$refs['processchoose'].choseSpUser({}, 0)
        return false;
      }
      return true

    },

    onSearch() {
      this.page.current_page = 1
      this.$refs.SXPurchaseTable.getList()

    },
    onReset() {
      this.queryForm = {}
      this.page.current_page = 1
      this.$refs.SXPurchaseTable.getList()

    },
    daochu(command) {
      return
      document.getElementById("addXlsbtn").click()

    },
    dep_Arr(arr) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.store_num <= 0) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },

    getType() {
      let _this = this
      this.$http.post("/api/zc_type", { type: 'hc' }).then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.typeList = result
        })
      })
    },
    getArea() {
      let _this = this
      this.$http.post("/api/zc_store").then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.areaList = result
        })
      })
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },

    iswebsiturl(websiturl) {
      if (websiturl.length > 8) {
        let urlHttps = websiturl.slice(0, 5);
        let urlHttp = websiturl.slice(0, 4);
        if (urlHttps == "https") {
          let https = websiturl.slice(0, 8);
          if (https == "https://") { return true; } else { return false; }
        } else if (urlHttp == "http") {
          let http = websiturl.slice(0, 7);
          if (http == "http://") { return true; } else { return false; }
        } else { return false; }
      } else { return false; }
    },
    saveForm() {
      let flag = 1
      //--审批流程用----
      if (!this.preCheckData()) {
        return
      }
      //--审批流程用----
      let _this = this
      let params = {}
      console.log(this.EditItem)
      params = { ..._this.EditItem }
      if (!_this.arr_json || _this.arr_json == 0) {
        _this.$message.warning('请至少添加一条耗材~')
        return
      } else {
        _this.arr_json.map(a => {
          // console.log(755755,a.cgNum)
          if (!a.cgNum) {
            _this.$message.warning(`请填写${a.title}的采购数量~`)
            flag = 0
            return
          }
          let links = []
          if (a.externalLinks) {
            if (a.externalLinks.indexOf(';') > 0) {
              links = a.externalLinks.split(';')
            } else if (a.externalLinks.indexOf('；') > 0) {
              links = a.externalLinks.split('；')
            } else {
              links = [a.externalLinks]
            }
            links.map(url => {
              if (!this.iswebsiturl(url)) {
                _this.$message.warning(`【${a.title}】的链接地址“${url}”无法正确跳转，请检查~`)
                flag = 0
                return
              }
            })
          }
        })
        params.hcList = _this.arr_json
        params.title = `${_this.userinfo.username}发起了${_this.arr_json[0].title}${_this.arr_json.length > 1 ? ('等' + _this.arr_json.length + '件物品') : ''}的申购单`
      }
      params.files = JSON.stringify(_this.files)

      //--审批流程用----
      params.sp_status = 0;
      params.process_id = this.process_id
      params.checkedUsers = this.choosedUser
      //--审批流程用----
      params.zc_type = 'sx'
      if (flag) {
        this.$http.post('/api/sx_sg_save', params).then(res => {
          if (res.data.code == 200 || res.data.affectedRows || res.data.id) {
            _this.dialogVisible_add = false;
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            _this.$refs.SXPurchaseTable.getList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }

        })
      }

    },
    removeItem(item) {
      let _this = this
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/sx_sg_delete", {
          id: item.id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          });

          _this.$refs.SXPurchaseTable.getList()

        })

      }).catch(() => {

      });
    },
    delSlct() {
      let _this = this
      if (_this.multipleSelection.length == 0) {
        _this.$message.warning('请选择至少一条数据')
      } else {
        _this.$confirm('此操作将移除所选数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(_this.multipleSelection, _this.arr_json)
          _this.multipleSelection.map(a => {
            let len = _this.arr_json.length
            for (let i = len - 1; i >= 0; i--) {
              if (a.id == _this.arr_json[i].id) {
                _this.arr_json.splice(i, 1)
              }
            }
          })
          console.log(874, _this.arr_json)


          let total = 0
          if (_this.arr_json && _this.arr_json.length > 0) {
            _this.arr_json.map(a => {
              a.cgTotal = 0
              if ((!a.cgJine && a.cgJine != 0) || (!a.cgNum && a.cgNum != 0)) {
                a.cgTotal = 0
              } else {
                a.cgTotal = this.$mul(a.cgJine, a.cgNum)
              }
              total += a.cgTotal
            })
            this.EditItem.howmuch = total
          } else {
            this.EditItem.howmuch = 0
          }
          _this.$nextTick(() => {
            // console.log(_this.arr_json)
            _this.$forceUpdate()
          })
        }).catch(() => {

        });
      }
    },





    showUser(type) {
      if (type) {
        this.userDialogType = type
      }
      this.showUserDialog = true
    },
    del_target_users(index) {
      let _this = this
      this.EditItem.target_users.splice(index, 1)
      this.EditItem = { ..._this.EditItem }
    },
    getUser(e) {
      // console.log(e)
      let _this = this
      let arr = []
      if (e && e.length > 0) {
        if (this.userDialogType == 1) {
          e.map((item, idx) => {
            arr.push({
              union_id: item.union_id,
              name: item.name,
            })
            if (_this.EditItem.target_users && _this.EditItem.target_users.length > 0) {
              _this.EditItem.target_users.map(orgnl => {
                if (orgnl.union_id == item.union_id) {
                  arr.splice(idx, 1)
                }
              })
            }
          })
          _this.EditItem.target_users = _this.EditItem.target_users.concat(arr)
          _this.EditItem = { ..._this.EditItem }
        } else if (this.userDialogType == 2) {
          _this.EditItem.target_uid = e[0].union_id
          _this.EditItem.name = e[0].name
          _this.EditItem = { ..._this.EditItem }
        }

      }
      _this.showUserDialog = false
    },
    closeUser() {
      this.showUserDialog = false
    },

    print_ruku() {
      this.$alert("敬请期待")
      return;
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请选择一条数据');
        return
      } else {
        this.showPrinter = true
      }
    },
    getCurDate() {
      let d = new Date();
      let year = d.getFullYear(); //获取完整的年份(4位)
      let month = (d.getMonth() + 1).toString().padStart(2, '0'); //获取当前月份(0-11,0代表1月)
      let date = (d.getDate()).toString().padStart(2, '0'); //获取当前日(1-31)
      d.getDay(); //获取当前星期X(0-6,0代表星期天)
      d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
      d.getHours(); //获取当前小时数(0-23)
      d.getMinutes(); //获取当前分钟数(0-59)
      d.getSeconds(); //获取当前秒数(0-59)
      d.getMilliseconds(); //获取当前毫秒数(0-999)
      d.toLocaleDateString(); //获取当前日期
      let mytime = d.toLocaleTimeString(); //获取当前时间
      d.toLocaleString(); //获取日期与时间
      this.curDate = `${year}-${month}-${date}`
    },
    uploadAttch(e) {
      // console.log(e)
      // this.$http.post("/api/import_zc_xls", { url: e.src }).then(res => {
      //   if (res.data.errordata.length > 0) {
      //     this.ErrLog = res.data.errordata
      //     this.dialogErrorVisible = true;

      //   }
      //   this.$refs.SXPurchaseTable.getList()

      // })
    },
    getOrgList() {
      let _this = this;
      return new Promise((resolve) => {
        this.$http.post("/api/teacher_department_tree").then((res) => {
          if (res.data && res.data.length > 0) {
            _this.deleteEmptyGroup(res.data).then((result) => {
              _this.deptTree = result;
            });
          }
        });
      });
    },
    changeDept(e) {
      let labels = this.$refs.treeCascader.getCheckedNodes()[0].pathLabels
      if (labels && labels.length > 0) {
        this.EditItem.dept_name = labels[labels.length - 1]
        console.log(this.EditItem.dept_name);
      }
    },
  }
}
</script>

<style lang="less" type="text/less">
.pagesxpurchase {
  box-shadow: 0 0 5px #409eff81;
  padding-left: 10px;
  padding-bottom: 10px;
  position: relative;

  .addDialog {
    background-color: #fff;
    //border: 1px solid #409eff;
    box-shadow: 0 0 5px #409eff81;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: calc(100%);
    height: calc(100%);

    .el-dialog__wrapper {
      position: absolute !important;
    }

    .v-modal {
      position: absolute !important;
    }

  }


  .myDialogBox {
    width: 100%;
    height: 100%;

    .shadow {
      box-shadow: 0 0 5px #409eff81;
    }

    .titleBar {
      width: 100%;
      height: 30px;
      background-color: #2469f351;
      font-size: 16px;
      flex-wrap: wrap;
    }

    .titleBar .div {
      width: 100%;
      height: calc(100% - 2px);
      padding-left: 22px;
      border-left: none;
      color: #303133;
      font-weight: 700
    }

    .el-form-item {
      margin-bottom: 0;

      .el-textarea__inner {
        margin: 10px 0;
      }
    }

    .cgInput {
      max-width: 120px;

      .el-input__inner {
        color: #F56C6C;
      }
    }

    .cgInput input::-webkit-outer-spin-button,
    .cgInput input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    .cgInput input[type="number"] {
      -moz-appearance: textfield;
    }


  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown+.el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box,
  .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 20px 0 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    //overflow: hidden;
    background-repeat: no-repeat;
    //cursor: pointer;
  }

  .uploadBox:hover {
    border-color: #409eff !important;
    color: #409eff;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}
</style>
